import { LOGIN_TYPE } from 'src/app/constant';
import { LOCAL_KEY_BUCKETS} from 'src/app/constant';
export const environment = {
  production: false,
  title: 'S3 Manager',
  logins: [
    {
      type: LOGIN_TYPE.cognito,
      name: 'Amazon Cognito',
      region: 'ap-south-1',
      identityPoolId: 'ap-south-1:63ed334a-7f14-416c-bc81-524ca2b43d77',
      userPoolId: 'ap-south-1_8W7XPrFQQ',
      userPoolWebClientId: 'f0gi1dus1qq2b4pisatc0ubo1',
    },
    {
      type: LOGIN_TYPE.accessKey,
      name: 'AWS Access Key',
      region: 'ap-south-1',
    },
  ],
};
const buckets = new Set<string>();
buckets.add('aws-landing-zone-s3-access-logs-854743806951-ap-south-1,,');
buckets.add('igenesys-input');
buckets.add('igenesys-vpc-flow-logs');
buckets.add('igenesys-wp');
buckets.add('stackset-genesys-aws-lan-landingzonepipelineartif-n6jscwqrz3wl');
localStorage[LOCAL_KEY_BUCKETS] = JSON.stringify(Array.from(buckets));
